
import { defineStore } from 'pinia'

import { api_request } from './Request'
const APP_URL = process.env.VUE_APP_APP_URL;

import axios from 'axios'
export const templateStore = defineStore('template', {
    state: () => {
        return {
            loading: true,
            search: "",
            newTemplate: {
                title: 'New Template - ' + new Date().toISOString().slice(0, 10),
                qrOptions: null,
                imagePath: "",
                id: 0
            },
            list: []
        }
    },
    actions: {
        resetTemplate() {
            this.newTemplate = {
                title: 'New Template - ' + new Date().toISOString().slice(0, 10),
                qrOptions: null,
                imagePath: "",
                id: "0"
            }
        },
        getTemplate(templateId) {
            return new Promise((resolve, reject) => {
                if (templateId === "0") {
                    this.resetTemplate();
                    resolve(this.newTemplate)
                }
                else {
                    this.loading = true
                    api_request.post("/template/GET", { "id": templateId })
                        .then(async (response) => {
                            if (response.data != null) {
                                // await this.checkLogo(response.data) ;                          
                                this.newTemplate = response.data
                                this.loading = false
                                resolve(response.data)

                            } else {
                                reject(null)
                            }

                        }).catch((error) => {
                            reject(error)
                        })


                }
            })
        },
        saveTemplate() {
            return new Promise((resolve, reject) => {
                this.loading = true;
                let data = this.newTemplate;
                api_request.post("/template/SAVE_TEMPLATE", data)
                    .then(response => {
                        if (response.data != null) {
                            this.loading = false;
                            resolve(response.data)
                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
        loadTemplates() {
            return new Promise((resolve, reject) => {
                this.loading = true
                api_request.post("/template/LOAD_TEMPLATES", { search: this.search })
                    .then(response => {
                        if (response.data != null) {
                            this.loading = false
                            this.list = response.data;
                            resolve(true)
                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
        deleteTemplate(template) {
            this.loading = true
            return new Promise((resolve, reject) => {
                api_request.post("/template/DELETE_TEMPLATE", { id: template.id }).then(() => {
                    resolve(true)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        markFavorite(template) {
            this.loading = true
            return new Promise((resolve, reject) => {
                api_request.post("/template/MARK_FAV_TEMPLATE", { id: template.id }).then((response) => {
                    let userDetails = window.localStorage.user_details;
                    let userDetailsJson = JSON.parse(userDetails);
                    userDetailsJson.account.account = response.data;
                    window.localStorage.user_details = JSON.stringify(userDetailsJson)
                    this.loading = false
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
})    