<template>

    <body>
        <div
            class="py-12 bg-gray-500 w-full h-screen bg-opacity-50  absolute z-30 transition duration-150 ease-in-out   top-0 right-0 bottom-0 left-0">
            <div role="alert" class="container mx-auto w-11/12 md:w-1/2 max-w-lg flex justify-center">
                <div class="relative w-11/12  bg-white  shadow pt-10 pb-8 rounded">
                    <div class="flex flex-col items-center px-4 md:px-12">
                        <div class="flex flex-col m-8 rounded shadow-md w-60 sm:w-80 animate-pulse h-96">
                            <div class="h-48 rounded-t bg-gray-300"></div>
                            <div class="flex-1 px-4 py-8 space-y-4 sm:p-8 bg-gray-50">
                                <div class="w-full h-6 rounded bg-gray-300"></div>
                                <div class="w-full h-6 rounded bg-gray-300"></div>
                                <div class="w-3/4 h-6 rounded bg-gray-300"></div>
                            </div>
                        </div>
                        <!-- <p class="text-base sm:text-lg md:text-2xl font-bold md:leading-6 mt-6 text-gray-800 text-center ">
                            Please provide your name</p> -->

                        <div class="flex flex-wrap items-center mt-4 sm:mt-6 w-full gap-2">
                            <div class=" flex-col w-full flex-warp justify-center items-center">
                                <span>Your Name</span>
                                <input @change="nameChnaged"
                                    class=" mt-1 flex appearance-none md:mx-1 w-full rounded border-gray-300  py-2 px-4 text-gray-700 leading-tight "
                                    id="inline-full-name" placeholder="Please Enter Your Name" type="text"
                                    :value="value.userName">
                                <span class="mt-5 flex">Account Name</span>
                                <input @change="accountChnaged"
                                    class=" mt-1 flex appearance-none md:mx-1 w-full rounded border-gray-300  py-2 px-4 text-gray-700 leading-tight "
                                    id="inline-full-name" placeholder="Please Enter Your Account Name" type="text"
                                    :value="value.currentAccountName">
                            </div>
                            <div class=" flex w-full justify-center items-center">
                                <button @click="saveName" :disabled="!nameEntered"
                                    class="text-white ml-4 cursor-pointer w-1/4 focus:outline-none border border-transparent disabled:opacity-50 focus:border-gray-800 focus:shadow-outline-gray bg-[#155E75] transition duration-150 ease-in-out hover:bg-[#358da8] h-9 rounded flex items-center justify-center">
                                    Let's Go
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </body>
</template>
<script>
import Loading from '@/components/Loading.vue';
import { registerStore } from '@/stores/Register';
import { userStore } from '@/stores/User';
export default {
    name: 'AppOnboarding',
    props: {
        value:{
            type: Object,
            default: {}
        }
    },
    setup() {
        const registerStoreIs = registerStore();
        const userStoreIs = userStore();
        return {
            register: registerStoreIs,
            user: userStoreIs
        };
    },

    data() {
        return {
            nameEntered:false,
          
        };
    },
    mounted() {

    },
    computed: {
        // nameEntered() {
        //     if (this.name.trim().length > 0 && this.accountName.trim().length > 0) {
        //         return true;
        //     }
        //     return false
        // },
        // name(){ return this.value.userName},
        // accountName(){ return this.value.currentAccountName}
    },
    methods: {
        nameChnaged(e) {

            this.value.userName = e.target.value
            if (this.value.userName.trim().length > 0 && this.value.currentAccountName.trim().length > 0) {
                this.nameEntered = true
            }else{
                this.nameEntered=false
            }
        },
        accountChnaged(e) {
            this.value.currentAccountName = e.target.value
            if (this.value.userName.trim().length > 0 && this.value.currentAccountName.trim().length > 0) {
                this.nameEntered = true
            }else{
                this.nameEntered=false
            }
        },
        saveName() {
            this.loading = true;
            this.register.updateUserName(this.value.userName).then((userIs) => {
                this.user.firebaseUser = userIs;
                let user_details = JSON.parse(window.localStorage.user_details);
                let account_id= user_details.account.account.id
                let account = { firebaseId: userIs.uid, email: userIs.email,id:account_id, name: this.value.userName, accountName:this.value.currentAccountName }
                this.user.updateAccount(account).then((userAccount) => {
                    user_details.account.account = userAccount
                    localStorage.user_details = JSON.stringify(user_details);
                    this.loading = false;
                    this.$emit("nameSaved")
                })
            })
        }
    },
    components: { Loading }
};
</script>