
import { defineStore } from 'pinia'
import axios from 'axios'
const APP_URL = process.env.VUE_APP_APP_URL;
import { templateStore } from '@/stores/Template'
import { api_request } from './Request'

const navigation = {
    "MarketPro": [
        { title: "QR Codes", "route": "/codes", icon: "qrcodes" },
        { title: "Templates", "route": "/templates", icon: "templates" },
        { title: "Contents", "route": "/contents", icon: "contents" },
        { title: "Folders", "route": "/folders", icon: "folders" },
        { title: "Lead Forms", "route": "/lead-forms", icon: "forms" },
        { title: "Campaigns", "route": "/campaigns", icon: "campaigns" },
    ],
    "OnDemand": [
        { title: "QR Codes", "route": "/codes", icon: "qrcodes" },
        { title: "Templates", "route": "/templates", icon: "templates" },
        { title: "Contents", "route": "/contents", icon: "contents" },
        { title: "Folders", "route": "/folders", icon: "folders" },
        { title: "Lead Forms", "route": "/lead-forms", icon: "forms" },
        { title: "Campaigns", "route": "/campaigns", icon: "campaigns" },
    ],
    "JustForYou": [
        { title: "QR Codes", "route": "/codes", icon: "qrcodes" },
        { title: "Templates", "route": "/templates", icon: "templates" },
        { title: "Contents", "route": "/contents", icon: "contents" },
        { title: "Folders", "route": "/folders", icon: "folders" },
        { title: "Lead Forms", "route": "/lead-forms", icon: "forms" },
        { title: "Campaigns", "route": "/campaigns", icon: "campaigns" },

    ],
    "External": [{ title: "Dashboard", "route": "/dashboard", icon: "dashboard" },
    { title: "QR Codes", "route": "/codes", icon: "qrcodes" }]
}
export const userStore = defineStore('user', {
    state: () => {
        return {
            role: '',
            defaultTemplateOptions: {},
            firebaseUser: {},
            selectedSubAccount: {},
            showSubAccount: false,
            limit: {
                exceeded: false,
                message: ""
            }

        }
    },
    getters: {
        avatarDetails(state) {
            let str = state.firebaseUser.displayName;
            if (str) {
                var hash = 0;
                for (var i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }
                var h = hash % 360;
                let bgcolor = 'hsl(' + h + ', 30%, 80%)';
                let initialsArray = str.split(" ");
                let initials = initialsArray[0].slice(0, 1);
                if (initialsArray.length > 1) {
                    initials = initials + initialsArray[1].slice(0, 1)
                }
                return [bgcolor, initials]
            }
            return []

        }
    },
    actions: {

        updateAccount(data) {
            return new Promise((resolve, reject) => {
                data.profileId = localStorage.profileId
              
                axios.post(APP_URL + "/account/UPDATE_ACCOUNT", data)
                    .then(response => {
                        if (response.data != null) {
                          
                            resolve(response.data)
                        } else {
                            resolve(null)
                        }

                    });

            })
        },
        createAccount(data) {
            return new Promise((resolve, reject) => {
                data.profileId = localStorage.profileId
                localStorage.removeItem('profileId');
                axios.post(APP_URL + "/account/CREATE_ACCOUNT", data)
                    .then(response => {
                        if (response.data != null) {
                            this.role = response.data.account.type
                            resolve(response.data)
                        } else {
                            resolve(null)
                        }

                    });

            })
        },
        fetchAccount(data) {
            return new Promise((resolve, reject) => {
                axios.post(APP_URL + "/account/FETCH_ACCOUNT", data)
                    .then(response => {
                        if (response.data != null) {
                            this.role = response.data.account.type
                            if (response.data.account.favtemplate != null) {
                                let templateStroreIs = templateStore();
                                templateStroreIs.getTemplate(response.data.account.favtemplate).then((template) => {
                                    this.defaultTemplateOptions = template;
                                    resolve(response.data)
                                })
                            }
                            else {
                                resolve(response.data)
                            }

                        } else {
                            resolve(null)
                        }

                    });

            })
        },
        getNavigationOptions() {
            // return new Promise((resolve, reject) => {
            //     let options= navigation[this.role];
            //     resolve(options);
            // })
            let sub = window.localStorage.sub_account;
            let navi=[]
            if (sub) {
                navigation[this.role].forEach(element => {
                    if(!(element.title==='Folders')){
                        navi.push(element)
                    }
                });
            return navi;
            }
            else {
                return navigation[this.role];
            }
        },
        checkMetered(route) {
            return new Promise((resolve, reject) => {
                let type = route.meta.type
                this.loading = true
                api_request.post("/account/CHECK_METERED", {})
                    .then(response => {
                        if (response.data != null && response.data.metered != undefined) {
                            let metered = response.data.metered;
                            let type_metered = metered[type];
                            debugger;
                            if (type_metered) {
                                if (type_metered.consumed < type_metered.allowed) {
                                    this.limit = {
                                        exceeded: false
                                    }
                                    resolve({ allowed: true })
                                } else {
                                    this.limit = {
                                        exceeded: true,
                                        header: `You can not create more than ${type_metered.allowed} ${type}`,
                                        message: `Only  ${type_metered.allowed} ${type} Allowed in your current plan. `
                                    }
                                    resolve({ allowed: false })
                                }
                            }


                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
    },
})    