
import { defineStore } from 'pinia'
import axios from 'axios'
const QR_CODE_URL = process.env.VUE_APP_QR_CODE_URL;
const LONG_APP_URL = process.env.VUE_APP_LONG_URL;
const FORM_URL = process.env.VUE_APP_FORM_URL;
import { api_request } from './Request'
export const qrStore = defineStore('qr', {
    state: () => {
        return {
            loading: true,
            search: "",
            selectedTemplate: null,
            svgUrl: "",

            //typeOptions

            newQR: {
                title: 'New QR - ' + new Date().toISOString().slice(0, 10),
                qrOptions: [],

                type: "",
                typeLabel: "",
                qrstatus: "",
                includeInVcard: false,
                schedule: false,
                selectedTemplateId: null,
                validated: false,
                validationMessage: "",
                validation: {
                    "link": ["link"],
                    "tel": ["tel"],
                    "vcard": ["vname"],
                    "email": ["mailto", "subject"],
                    "sms": ["sms"],
                    "wifi": ["ssid", "networktype"],
                    "zoom": ["zoom_id", "zoom_pwd"],
                    "pdf": ["uploadedPdf"]

                },
                imagePath: "",
                id: "0",
                //the value below we need to change on upload
                shortUrl: null,
                typeOptions: {

                },
                leads: {
                    collectLeads: false,
                    leadForm: null,
                    name: null
                },
                validated: false,
            },
            list: []
        }
    },
    getters: {
        getType(state) {
            let type = "#" + state.newQR.type;
            if (state.newQR.type === 'pdf' || state.newQR.type == 'multiple') {
                type = "#link";
            }
            return type;
        },
        getOptionsData(state) {
            let optionsData = {};
            Object.assign(optionsData, state.newQR.typeOptions);
            if (state.newQR.shortUrl != null) {
                optionsData.link = state.newQR.shortUrl.surl
            }
            optionsData["section"] = this.getType
            optionsData["size"] = 24;
            optionsData["level"] = "Q";
            state.newQR.qrOptions.forEach(element => {

                if (element.title == 'Colors') {
                    optionsData["backcolor"] = element.backcolor;
                    optionsData["frontcolor"] = element.frontcolor
                    optionsData["gradient_color"] = element.gradient_color;
                    optionsData["marker_out_color"] = element.marker_out_color;
                    optionsData["marker_in_color"] = element.marker_in_color;
                    optionsData["marker_top_right_outline"] = "#000000";
                    optionsData["marker_top_right_center"] = "#000000";
                    optionsData["marker_bottom_left_outline"] = "#000000";
                    optionsData["marker_bottom_left_center"] = "#000000";
                    if (element.gradient === 'on') {
                        optionsData["gradient"] = "on";
                    }
                    if (element.transparent === 'on') {
                        optionsData["transparent"] = "on";
                    }


                }
                else if (element.title == 'Design') {
                    optionsData["marker_out"] = element.marker_out,
                        optionsData["marker_in"] = element.marker_in,
                        optionsData["pattern"] = element.pattern,
                        optionsData["markers_color"] = element.markers_color
                }
                else if (element.title == 'Logo') {
                    optionsData["optionlogo"] = element.optionlogo,
                        optionsData["logo_size"] = element.logo_size
                }
                else {
                    optionsData["outer_frame"] = element.outer_frame,
                        optionsData["framelabel"] = element.framelabel,
                        optionsData["label_font"] = element.label_font,
                        optionsData["label-text-size"] = element.label_text_size,
                        optionsData["framecolor"] = element.framecolor,
                        optionsData["custom_frame_color"] = element.custom_frame_color

                }
            });
            
            return optionsData;
        }
    },
    actions: {
        generateShortUrl(dynamic) {
            return new Promise((resolve, rejcet) => {
              
                let data = { url: this.newQR.typeOptions.link, qrCode: this.newQR.id }
                if (this.newQR.leads != null && this.newQR.leads.leadForm != null && this.newQR.leads.leadForm.length > 0) {
                    data = { url: FORM_URL + "/?q=" + this.newQR.id + "&f=" + this.newQR.leads.leadForm, qrCode: this.newQR.id  }
                }
                if (this.newQR.shortUrl != null) {
                    data.id = this.newQR.shortUrl.id

                }
                data.dynamic = dynamic
                api_request.post("/shorturl/CREATE", data).then((response) => {
                    let shortUrl = response.data;
                    shortUrl.surl = LONG_APP_URL + "/" + response.data.id
                    this.newQR.shortUrl = shortUrl
                    resolve(response.data)
                })
            })
        },
        generateQRCode(data, access_token) {
            return new Promise((resolve, reject) => {
                axios.post(QR_CODE_URL + "?access_token=" + access_token, data)
                    .then(response => {
                        resolve(response)
                    });

            })

        },
        getQR(qrId, user, options) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                if (qrId === "0") {
                    let qr = this.newQR;
                    if (this.selectedTemplate == null) {
                        if (Object.keys(user.defaultTemplateOptions).length > 0) {
                            qr.qrOptions = user.defaultTemplateOptions.qrOptions;
                            this.selectedTemplateId = user.defaultTemplateOptions.id
                        }
                        else {
                            options.initOptions()
                            qr.qrOptions = options.options;
                        }
                    } else {
                        qr.qrOptions = this.selectedTemplate.qrOptions;
                        this.selectedTemplateId = this.selectedTemplate.id
                    }
                    this.loading = false;
                    resolve(qr)
                }
                else {
                    this.loading = true
                    api_request.post("/qrcode/GET", { "id": qrId })
                        .then(async (response) => {
                            if (response.data != null) {
                                // await this.checkLogo(response.data) ;   
                                response.data.type = response.data.qrtype
                                response.data.validation = JSON.parse(JSON.stringify(this.newQR.validation))
                                response.data.validated = true;
                                if (response.data.shorturlid != null && response.data.shorturlid != undefined) {
                                    response.data.shortUrl = { id: response.data.shorturlid, surl: LONG_APP_URL + "/" + response.data.shorturlid }
                                }
                                if (this.selectedTemplate != null) {
                                    response.data.qrOptions = this.selectedTemplate.qrOptions
                                }
                                this.svgUrl = JSON.parse(response.data.svgUrl);
                                delete response.data.svgUrl;
                                this.newQR = response.data
                                this.loading = false
                                resolve(response.data)

                            } else {
                                reject(null)
                            }

                        }).catch((error) => {
                            reject(error)
                        })
                }
            })
        },

        saveQR(publish) {

            return new Promise((resolve, reject) => {
                this.loading = true;
                let data = this.newQR;
                if (publish || this.newQR.qrstatus === 'Published') {
                    data.qrstatus = 'Published'
                }
                else {
                    data.qrstatus = 'Draft'
                }
                api_request.post("/qrcode/SAVE_QRCODE", data)
                    .then(response => {
                        if (response.data != null) {
                            this.loading = false;
                            resolve(response.data)
                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
        loadQr() {
            return new Promise((resolve, reject) => {
                this.loading = true
                api_request.post("/qrcode/LOAD_QRCODES", { search: this.search })
                    .then(response => {
                        if (response.data != null) {
                            this.loading = false
                            this.list = response.data;
                            resolve(true)
                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
        deleteQr(qr) {
            this.loading = true
            return new Promise((resolve, reject) => {
                api_request.post("/qrcode/DELETE_QRCODE", { id: qr.id }).then(() => {
                    resolve(true)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        uploadFile(fileInfo, cbProgress) {
            return new Promise((resolve, reject) => {
                axios.put(fileInfo.uploadUrl, fileInfo.file, {
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        cbProgress(percentCompleted);

                    },
                    headers: {
                        'Content-Type': fileInfo.contentType
                    }
                }).then((res) => {

                    resolve()
                }).catch((error) => {
                    console.log({ error });
                })
            })
        },
        uploadPdf(urldata, content) {
            debugger
            return new Promise((resolve, reject) => {
                axios.put(urldata.url.url, content, {
                    headers: {
                        'Content-Type': 'application/pdf'
                    }
                }).then(() => {
                    resolve()
                }).catch((error) => {
                    console.log({ error });
                })
            })
        }

    },
})    