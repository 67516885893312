<template>
    <div
        class="py-6 w-full h-screen bg-opacity-50  absolute z-30 transition duration-150 ease-in-out   top-0 right-0 bottom-0 left-0 bg-gray-200">
        <div class="container mx-auto">
            <div class="w-11/12 mx-auto mb-4 my-6 md:w-5/12 shadow sm:px-10 sm:py-6 py-4 px-4 bg-white rounded-md">
                <template >
                    <div class="flex pb-3 items-center">
                        <div class="-ml-1 text-gray-600">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash"
                                width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="4" y1="7" x2="20" y2="7" />
                                <line x1="10" y1="11" x2="10" y2="17" />
                                <line x1="14" y1="11" x2="14" y2="17" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg>
                        </div>
                        <p class="text-lg text-gray-800 font-semibold pl-2">{{ value.header }}</p>
                    </div>
                    <p class="text-sm text-gray-600 pb-3 font-normal">{{ value.message }}</p>
                </template>
                
                <div class="flex justify-end">
                    <button @click="action(value)"
                        class="focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs">{{buttonLabel}}
                        {{ type }}</button>
                    <button @click="closePopup"
                        class="ml-5 font-normal bg-gray-100 focus:outline-none transition duration-150 ease-in-out hover:bg-gray-300 rounded text-indigo-600 px-6 py-2 text-sm border border-gray-300">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppAlert',
    props: {
        value: {
            type: Object,
            default: {}
        }
        


    },

    data() {
        return {

        };
    },
    computed: {
        buttonLabel() {
            return this.actionName=='delete-item'?'Delete':'Mark Favorite';
        },
    
    },

    mounted() {

    },

    methods: {
        closePopup() {
            this.$emit('close-popup');
        },
        action(item) {
            this.$emit(this.actionName, item);
        }
    },
};
</script>
