
import { defineStore } from 'pinia'

import { api_request } from './Request'
const APP_URL = process.env.VUE_APP_APP_URL;

export const foldersStore = defineStore('folders', {
    state: () => {
        return {
            loading: true,
            search: "",
            newFolder: {
                id: 0,
                name: "",
                email: "",
                address: {},
                website: "",
                phoneNumber: ""
              

            },
            list: []
        }
    },
    getters: {
        readyToSave(state) {
            let validated = true;
            if (Object.keys(state.newFolder.address).length == 0 ||
                state.newFolder.address.formatted_address == undefined || state.newFolder.address.formatted_address.trim().length == 0) {
               
                validated = false;
            }
            if (
                state.newFolder.name.trim().length == 0) {
                
                validated = false;
            }
            return validated
        }
    },

    actions: {


        resetFolder() {

            this.newFolder = {
                id: 0,
                name: "",
                email: "",
                address: {},
                website: "",
                phoneNumber: "",
              
            }
        },
        getFolder(id) {
            return new Promise((resolve, reject) => {
                if (id === "0") {
                    this.resetFolder();
                    resolve(this.newFolder)
                }
                else {
                    api_request.post("/folders/GET", { "id": id })
                        .then(response => {
                            if (response.data != null) {
                                this.newFolder = response.data
                                resolve(response.data)
                            } else {
                                reject(null)
                            }

                        }).catch((error) => {
                            reject(error)
                        })


                }
            })
        },
        saveFolder() {
            return new Promise((resolve, reject) => {
                this.loading = true;
                let data = this.newFolder;
                console.log({data});
                
                api_request.post("/folders/SAVE_SUBACCOUNT", data)
                    .then(response => {
                        if (response.data != null) {
                            this.loading = false;
                            resolve(response.data)
                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
        loadFolders() {
            return new Promise((resolve, reject) => {
                this.loading = true
                api_request.post("/folders/LOAD_SUBACCOUNTS", { search: this.search })
                    .then(response => {
                        if (response.data != null) {
                            this.loading = false
                            this.list = response.data;
                            resolve(this.list)
                        } else {
                            reject(null)
                        }

                    }).catch((error) => {
                        reject(error)
                    })
            })
        },
        deleteFolder(folder) {
            this.loading = true
            return new Promise((resolve, reject) => {
                api_request.post("/folders/DELETE_FOLDER", { id: folder.id }).then(() => {
                    resolve(true)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        loadHtmlEmail(mjmlText) {
            
            return new Promise((resolve, reject) => {
                api_request.post("/mjmlEmail/MJML_HTML", {mjmltext:mjmlText  }).then((data) => {
                    resolve(data.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        loadJsonHtmlEmail(mjmlText) {
            
            return new Promise((resolve, reject) => {
                api_request.post("/mjmlEmail/MJML_JSON", {json:mjmlText  }).then((data) => {
                    resolve(data.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
})    