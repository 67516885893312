<template>
  <div id="app">
    
    <Layout></Layout>
    
  </div>
</template>
<script>
import Layout from './views/Layout.vue';


export default{
  name:"App",
  created() {
    var scripts = [
       "./crisp.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  components:{
    Layout
}

}
</script>

