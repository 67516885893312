<template>
    <div class="absolute  border-r   h-screen  z-20 p-2" style="width: 200px;background: #0E7490;">
        <div class="flex w-full">
            <div class="w-full flex justify-start flex-col transition duration-150 ease-in-out">

                <ul v-for="nav in navigation" aria-orientation="vertical" class="rounded">
                    <li
                        class="cursor-pointer mb-2 text-gray-50 text-sm leading-3 tracking-normal  focus:outline-none flex items-center">
                        <router-link :to="nav.route" class="items-center flex hover:router-link-exact-active  p-2 w-full ">
                            <span class="text-gray-50">
                                <Dashboard_i v-if="nav.icon == 'dashboard'" />
                                <Folder_i v-if="nav.icon == 'folders'" />
                                <Qrcodes_i v-if="nav.icon == 'qrcodes'" />
                                <Contents_i v-if="nav.icon == 'contents'" />
                                <Campaigns_i v-if="nav.icon == 'campaigns'" />
                                <Forms_i v-if="nav.icon == 'forms'" />
                                <Templates_i v-if="nav.icon == 'templates'" />

                            </span>
                            <span class="ml-2">{{ nav.title }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
import Dashboard_i from './icons/dashboard_i.vue';
import Folder_i from './icons/folder_i.vue';
import Campaigns_i from './icons/campaigns_i.vue';
import Qrcodes_i from './icons/qrcodes_i.vue';
import Templates_i from './icons/templates_i.vue';
import Contents_i from './icons/contents_i.vue';
import Forms_i from './icons/forms_i.vue';

export default {
    name: "LightWithHeaderAndIcons",
    props:{
        value:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
            //navigation: this.leftNavigation,
        };
    },
    computed: {
        navigation() {
            return this.value.getNavigationOptions();
        },
    
    },
    methods: {},
    components: { Dashboard_i, Folder_i, Campaigns_i, Qrcodes_i, Templates_i, Contents_i,Forms_i }
};
</script>
<style scoped>
    .router-link-exact-active {
    @apply text-gray-50;
    background: #155E75;
    border-radius: 5px;
    border: 1px solid #155E75;
  }

  .router-link-active {
    @apply text-gray-50;
    background: #155E75;
    border-radius: 5px;
    border: 1px solid #155E75;
  }
</style>
