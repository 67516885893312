import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      public: true,
    }
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('../views/Subscribe.vue'),
    meta: {
      public: true,
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/PaymentSuccessful.vue'),
    meta: {
      public: true,
    }
  },
  {
    path: '/validate',
    name: 'validate',
    component: () => import('../views/ValidateUser.vue'),
    meta: {
      public: true,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/templates',
    name: 'template',
    component: () => import('../views/Template.vue')
  },
  {
    path: '/campaigns',
    name: 'campaign',
    component: () => import('../views/Campaign.vue')
  },
  {
    path: '/codes',
    name: 'qr',
    
    component: () => import('../views/Qr.vue')
  },
  {
    path: '/contents',
    name: 'contents',
    component: () => import('../views/Contents.vue')
  },
  {
    path: '/contents/create-content/:id',
    name: 'create-contents',
    meta:{
      type:'contents'
    },
    component: () => import('../views/CreateNewContent.vue')
  },
  {
    path: '/templates/create-template/:id',
    name: 'create-template',
    meta:{
      type:'templates'
    },
    component: () => import('../views/CreateNewTemplate.vue')
  },
  {
    path: '/campaigns/create-campaign/:id',
    name: 'create-campaign',
    meta:{
      type:'campaign'
    },
    component: () => import('../views/CreateNewCampaign.vue')
  },
  {
    path: '/campaigns/details/:id',
    name: 'campaign-details',
    meta:{
      type:'campaign'
    },
    component: () => import('../views/CampaignDetails.vue')
  },
  {
    path: '/campaigns/automation/:id',
    name: 'automation',
    meta:{
      type:'campaign'
    },
    component: () => import('../views/Automation.vue')
  },
  {
    path: '/lead-forms',
    name: 'lead-forms',
    component: () => import('../views/Forms.vue')
  },
  {
    path: '/lead-forms/create-form/:id',
    name: 'create-lead-form',
    meta:{
      type:'forms'
    },
    component: () => import('../views/CreateLeadForm.vue')
  },
  {
    path: '/codes/create-qr/:id/:step',
    name: 'create-qr',
    meta:{
      type:'qrcodes'
    },
    component: () => import('../views/CreateNewQR.vue')
  },
  {
    path: '/codes/stats/:id',
    name: 'qr-stats',
    component: () => import('../views/QRAnalytics.vue')
  },
  {
    path: '/codes/choose-template/:id',
    name: 'choose-template',
    component: () => import('../views/Qr.vue')
  },
  {
    path: '/folders',
    name: 'folders',
    component: () => import('../views/Folders.vue')
  },
  {
    path: '/folders/create-folder/:id',
    name: 'create-folder',
    meta:{
      type:'folders'
    },
    component: () => import('../views/CreateNewFolder.vue')
  },
  {
    path: '/folders/details/:id',
    name: 'folder-details',
    meta:{
      type:'folders'
    },
    component: () => import('../views/FolderDetails.vue')
  },
  {
    path: '/change-account',
    name: 'change-account',
    meta:{
      type:'account'
    },
    component: () => import('../views/ChangeAccount.vue')
  },
  ,
  {
    path: '/email-editor',
    name: 'email-editor',
    meta:{
      type:'email-editor'
    },
    component: () => import('../views/EmailEditor.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/login",
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
