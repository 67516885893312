<template>
    <div class="w-full py-5  flex flex-col md:flex-row  md:justify-between">

        <div class="flex flex-col lg:flex-row  justify-between items-start lg:items-stretch w-full">
            <div class="w-full lg:w-3/4 flex flex-col lg:flex-row items-start lg:items-center">
                <h1 class="text-2xl  leading-normal mb-2  text-gray-600">{{ title }}</h1>
                <div class="relative  flex-1">
                    <input v-on:keyup.enter="titleSearch"
                        class=" appearance-none md:ml-5 relative w-full rounded  py-2 px-4 text-gray-700 leading-tight "
                        id="inline-full-name" type="text" @change="updateHeaderTitle" :value="value.search"
                        :placeholder="'Search ' + title">
                    <a @click.prevent="reset" href="" class="absolute right-0 top-2 " v-if="value.search.length>0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-x" viewBox="0 0 16 16">
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="w-full lg:w-1/2 flex flex-col lg:flex-row items-start lg:items-center justify-end">
                <!-- <div class="flex items-center lg:border-l lg:border-r border-gray-300 py-3 lg:py-0 lg:px-6">
                        <p class="text-base text-gray-600 dark:text-gray-400" id="page-view">Viewing 1 - 20 of 60</p>
                        <a class="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded" onclick="pageView(false)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <polyline points="15 6 9 12 15 18" />
                            </svg>
                        </a>
                        <a class="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer" onclick="pageView(true)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <polyline points="9 6 15 12 9 18" />
                            </svg>
                        </a>
                    </div> -->
                <!-- <div class="flex items-center lg:border-r border-gray-300 pb-3 lg:pb-0 lg:px-6">
                        <div class="relative w-32 z-10">
                            

                            <select aria-label="Selected tab" class="focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray text-base form-select block w-full py-2 px-2 xl:px-3 rounded text-gray-600 dark:text-gray-400 appearance-none bg-transparent">
                                <option>List View</option>
                                <option>Grid View</option>
                            </select>
                        </div>
                    </div> -->

                <div class="lg:ml-6 flex items-center">

                    <div
                        class="text-white ml-4 cursor-pointer focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-[#155E75] transition duration-150 ease-in-out hover:bg-indigo-600 w-8 h-8 rounded flex items-center justify-center">
                        <router-link :to="newRoute">

                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="28"
                                height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="12" y1="5" x2="12" y2="19" />
                                <line x1="5" y1="12" x2="19" y2="12" />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppHeader',
    props: {
        title: {
            type: String,
            default: ""
        }, newRoute: {
            type: String,
            default: ""
        }, value: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {
        updateHeaderTitle(e) {
            this.value.search = e.target.value

        },
        reset() {
            this.value.search = "";
            this.titleSearch();
        },
        titleSearch() {
            this.$emit('title-search');
        }
    },
};
</script>