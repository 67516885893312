import axios from 'axios'

const APP_URL = process.env.VUE_APP_APP_URL;

var api_request = axios.create({
    baseURL: APP_URL
})
api_request.interceptors.request.use(function (config) {
    
    if (window.localStorage.user_details != null) {
        let user = JSON.parse(window.localStorage.user_details);
        
        config.headers['userid'] = user?.account?.account?.id
        config.headers['accesstoken'] = user?.stsTokenManager?.accessToken
        config.headers['username'] = user?.displayName
        if(window.localStorage.sub_account!=null && config.url!='/folders/LOAD_SUBACCOUNTS'){
            let subAccount = JSON.parse(window.localStorage.sub_account)
            config.headers['accountid'] = subAccount?.id
        }
        else{
            config.headers['accountid'] = user?.account?.account?.id
        }
    }

    return config;
}, function (error) {

});


export {
    api_request
} 