import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from './App.vue'
import firebase from 'firebase/compat/app';
import router from './router'
import '@/assets/css/main.css'
import VCalendar from 'v-calendar';

const firebaseConfig = {
  // apiKey: "AIzaSyBZboqxNNhyxY8B9eJci6G_LJP_n6RfqPU",
  // authDomain: "allsellquick-74ca0.firebaseapp.com",
  // projectId: "allsellquick-74ca0",
  // storageBucket: "allsellquick-74ca0.appspot.com",
  // messagingSenderId: "624020343864",
  // appId: "1:624020343864:web:756350afabda6683476acd",
  // measurementId: "G-XTH93TTH8M"
  apiKey: "AIzaSyA3YMXW2THLJ-JKyqpupZZJ-aWm4CDMdqk",
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: "unitedqr",
  storageBucket: "unitedqr.appspot.com",
  messagingSenderId: "218076579497",
  appId: "1:218076579497:web:b6ffafca07db13cd65cbb8",
  measurementId: "G-JQ4QLZS0G6"
};
firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(user => {

  if (!user) {
    window.localStorage.removeItem('user_details')
  }
})
Vue.use(PiniaVuePlugin)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
const pinia = createPinia()
Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  router,
  pinia
}).$mount("#app")
